/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() { 
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        //alert('home');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single-case': {
      init: function() {
        // JavaScript to be fired on the about us page
        //alert('single_case');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


/*
CUSTOM FUNCTONS TO EXTEND JQUERY
*/

            /*
            Alineado vertical
            */

            // Llamar : $("#someDiv").verticalAlign();

            (function($){
              $.fn.extend({
                verticalAlign: function() {
                  //Iterate over the current set of matched elements
                  return this.each(function() {
                    var obj = $(this);
                    // calculate the new padding and height
                    var childHeight = obj.height();
                    var parentHeight = obj.parent().height();
                    //console.log("PH: " + parentHeight);
                    //console.log("childHeight: " + childHeight);
                    var diff = Math.round( ( (parentHeight - childHeight) / 2) );
                    //var diff = Math.round( ( (childHeight) / 2) );
                    // apply new values
                    obj.css( { "display": "block", "margin": 0 , "margin-top": diff} );
                    //console.log(diff);
                  });
                }
              });
            })(jQuery);

            function scrollToTop_init(){
              /*Scroll toTop*/
              function onScroll(){
                if (jQuery(this).scrollTop()) {
                  jQuery('#toTop:hidden').stop(true, true).fadeIn();
                } else {
                  jQuery('#toTop').stop(true, true).fadeOut();
                }
              }
              jQuery(window).scroll(onScroll);
              //Click event to scroll to top
              jQuery('#toTop').click(function(e){
                e.preventDefault();
                jQuery('html, body').animate({scrollTop : 0},800);
                return false;
              });   
            }

          
            function matchHeight(mc){
               var heights = jQuery(mc).map(function() {
                  return jQuery(this).height();
              }).get(),

              maxHeight = Math.max.apply(null, heights);

              jQuery(mc).height(maxHeight);
            }

            /*
            REGEX to detect mobile devices
            */

            var isMobileBrowser = function(){
              var check = false;
              (function(a){
                  if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))){
                      check = true;
                  }
              })(navigator.userAgent||navigator.vendor||window.opera);
              return check;
            };

 
            //Stoop videos from iframes youtube vimeo ...

            var  stopVideoIfr = function( mc ) {
              //Basically, it kills the <iframe> and rebuilds it. - API wont work well
                vimeoWrap = mc;
                vimeoWrap.html(vimeoWrap.html());
            };

            //Extend $ to see if an element is visible
            (function ($) {
             
                /**
                * Tests if a node is positioned within the current viewport.
                * It does not test any other type of "visibility", like css display,
                * opacity, presence in the dom, etc - it only considers position.
                * 
                * By default, it tests if at least 1 pixel is showing, regardless of
                * orientation - however an optional argument is accepted, a callback
                * that is passed the number of pixels visible on each edge - the return
                * (true of false) of that callback is used instead.
                */
                $.fn.inViewport = function(test){
             
                    var height = this.outerHeight();
                    var width = this.outerWidth();
             
                    if(!width || !height){
                        return false;
                    }
                    
                    var win = $(window);
             
                    var viewport = {
                        top : win.scrollTop(),
                        left : win.scrollLeft()
                    };
                    viewport.right = viewport.left + win.width();
                    viewport.bottom = viewport.top + win.height();
             
                    var bounds = this.offset();
                    bounds.right = bounds.left + width;
                    bounds.bottom = bounds.top + height;
                    
                    var showing = {
                      top : viewport.bottom - bounds.top,
                      left: viewport.right - bounds.left,
                      bottom: bounds.bottom - viewport.top,
                      right: bounds.right - viewport.left
                    };
             
                    if(typeof test === 'function') {
                      return test(showing);
                    }

                    
                    return showing.top > win.height()*0.3 && showing.left > 0 && showing.right > 0 && showing.bottom >  win.height()*0.3;
                };
             
            })(jQuery);